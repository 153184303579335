/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slick-slider.slick-initialized{
  position: absolute;
}

.slick-slider .slick-list{
  height: 100%;
}

.slick-list .slick-track {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.slick-list .slick-track .slick-slide{
  height: fit-content;
}

.box-image{
  display: flex !important;
}